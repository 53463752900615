import Message from './Message'
import Typing from './Typing';
import TextLanguage from './TextLanguage';



function Trial({language}) {
  return (
    <div>
      <Message sender={true}><TextLanguage dk={'Jeg vil gerne prøvetræne'} en={'I would like to try out a training session'} language={language}/> 💪 </Message>
      <Typing>
        <Message><TextLanguage dk={'⚠️ Bemærk ⚠️ Vi er ikke et fitnesscenter, vi er en styrkeløftklub.'} en={'⚠️ Note ⚠️ We are not a gym, we are a powerlifting club.'} language={language} /> </Message>
        <Message><TextLanguage dk={'Vi har midlertidigt lukket for prøvetræninger og optag af nye medlemmer. Vi opdaterer siden, når der åbnes op igen.'} en={'We have temporarily closed for trial sessions and new member applications. This is a temporary pause, and we will update this page as soon as we reopen for new sign-ups.'} language={language}></TextLanguage></Message>
        {/* <Message><TextLanguage dk={'Hvis du vil prøvetræne hos os, så tag fat i formand Per Berndorff på telefon'} en={'If you want to try training with us, please contact chairman Per Berndorff by phone'} language={language} /> 20&nbsp;20&nbsp;18&nbsp;22📱</Message> */}
      </Typing>
    </div>
  )
}

export default Trial