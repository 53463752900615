import Message from './Message'
import Typing from './Typing'
import TextLanguage from './TextLanguage'

function OpeningHours({language}) {

  return (
    <div>
        <Message sender={true}><TextLanguage dk={'Hvornår åbner klubben'} en={'What are your opening hours'} language={language}/>? 🤔 </Message>
        <Typing>
            <Message>🕜 <TextLanguage dk={'Her er klubbens faste åbningstider'} en={'Here are the our regular opening hours'} language={language}/>:<br/><br/>
            <TextLanguage dk={'Mandag'} en={'Monday'} language={language} />: 12-19<br/>
            <TextLanguage dk={'Tirsdag'} en={'Tuesday'} language={language} />: 16-19<br/>
            <TextLanguage dk={'Onsdag'} en={'Wednesday'} language={language} />: 12-19<br/>
            <TextLanguage dk={'Torsdag'} en={'Thursday'} />: 16-19<br/>
            <TextLanguage dk={'Fredag'} en={'Friday'} language={language} />: 12-19<br/>
            <TextLanguage dk={'Lørdag'} en={'Saturday'} language={language} />: 10-13
            </Message>
        </Typing>
    </div>
  )
}

export default OpeningHours